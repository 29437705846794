.root {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid var(--base25);
  line-height: 1.25rem;
  color: var(--ambient-base);
  transition: border 0.5s;
  min-height: 1.875rem;
  padding: 5px 0;
  font-size: 0.875rem;
  cursor: pointer;
}

.root:focus {
  outline: 0;
  border-bottom: 1px solid var(--base75);
}

.group {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.selectContainer {
  position: relative;
}

.menu {
  position: absolute;
  padding: 0;
  left: 0;
  right: 0;
  border: 1px solid var(--base25);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: white;
  z-index: 1;
}

.selectedItem {
  background-color: var(--ambient-basePlus75);
  display: flex;
  min-width: 0px;
  box-sizing: border-box;
  border-radius: 0.125rem;
  margin: 0.125rem;
}

.selectItems,
.selectItemsNoOption {
  padding: 0.375rem 0.875rem;
  text-transform: capitalize;
}
