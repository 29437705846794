:focus {
  outline: 0;
}

.slideWrapper {
  position: relative;
}

.slideBar {
  position: absolute;
  background-color: #ebebeb;
  width: 100%;
  height: 15px;
  left: 6px;
  border-radius: 10px;
}

.slideOverlay {
  position: absolute;
  background-color: var(--accent-base);
  height: 15px;
  border-radius: 10px;
}

.rangeSlider {
  display: block;
  position: relative;
  z-index: 10;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  height: 15px;
  width: calc(100% + 12px);
}

.rangeSlider::-moz-range-track {
  background: transparent;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--accent-base);
  cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: none;
  background: var(--accent-base);
  cursor: pointer;
}

.valueText {
  display: block;
  text-align: center;
  margin-left: 12px;
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 17px;
  color: var(--accent-base);
}
