.root {
  position: relative;
}

.input {
  display: block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus {
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.list {
  background-color: white;
  z-index: 100;
  position: absolute;
  top: 2.3rem;
  right: 50%;
  left: 0;
  height: 50vh;
  overflow-y: scroll;
  border: 1px solid #ced4da;
}

.listElement {
  padding: 0.5rem;
  cursor: pointer;
}

.listElement:hover {
  background-color: #eee;
}

.isLoading {
  color: #eee;
  cursor: default;
}

.isLoading:hover {
  background-color: inherit;
}
