.sentinelTypeIconContainer {
  position: absolute;
  width: 60px;
  height: 60px;
  left: -30px;
  top: 55px;
  background: linear-gradient(-136deg, #2adec6 0%, var(--accent-base) 100%);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 2.85px var(--accent-baseMinus10);
}
