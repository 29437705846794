.scheduleHeaderText {
  padding-bottom: 2rem;
  text-align: center;
}
.scheduleText {
  text-align: center;
  font-weight: 700;
  color: var(--ambient-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 12rem;
}

.background1 {
  background: url('../../../demoAssets/Sent_schedule_3.png');
  background-size: cover;
}

.background2 {
  background: url('../../../demoAssets/Sent_schedule_3.png');
  background-size: cover;
}

.background3 {
  background: url('../../../demoAssets/Sent_schedule_3.png');
  background-size: cover;
}

.schedulerContainer {
  padding-left: 15.625rem;
  padding-right: 15.625rem;
  padding-bottom: 2.5rem;
}
