@import '../../../../root.css';

.stepLeft,
.stepRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stepRight {
  width: 40%;
}

.stepLeft p {
  font-weight: 600;
  color: var(--ambient-base);
}

.stepRight p {
  font-size: 0.75em;
  line-height: 1.25em;
  color: var(--ambient-base);
  padding-left: 10px;
  margin: 0;
}

.checkboxWrapper {
  display: flex;
  justify-content: space-around;
  padding-top: 2rem;
}

.bottomConditionsWrapper {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  font-size: 14px;
  line-height: 19px;
}

.conditionLabel {
  width: 8.75rem;
  display: inline-block;
}

.conditionScale,
.conditionReaction {
  display: flex;
}

.conditionScale .left,
.conditionReaction .left {
  width: 60%;
  display: inline-block;
}

.conditionReaction {
  padding-top: 1rem;
}

.helpText {
  display: flex;
  padding-top: 1.5rem;
}

.helpText > svg {
  flex-shrink: 0;
}

.infoIcon {
  color: var(--ambient-base);
}
