@import '../../../../root.css';

.conditionalsWrapper {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: 0 auto;
}

.stepWrapper h5 {
  margin: 0 auto;
  text-align: center;
  width: 60%;
  padding-bottom: 2rem;
}
