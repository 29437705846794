.wrapper {
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding: 0.6875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
  padding-right: 1rem;
}

.error {
  background-color: var(--pink-basePlus90);
  color: var(--pink-base);
}

.warning {
  background-color: var(--orange-basePlus90);
  color: var(--orange-base);
}

.note {
  background-color: var(--turquoise-basePlus75);
  color: var(--ambient-base);
}
