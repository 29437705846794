.root {
  display: inline-block;

  font-size: 0.625rem;
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
  font-weight: 400;
}

.defaultColor {
  background-color: var(--accent-base);
  color: white;
}

.uppercase {
  text-transform: uppercase;
}

.rootFullWidth {
  display: block;
}
