.notificationCountButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1.25rem;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
}
