.wrapper {
  height: 3.375rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconWrapper {
  position: absolute;
  top: calc(50%);
  left: 1rem;
  transform: translateY(-50%);
  transition: transform 0.2s;
}

.searchInput {
  font-size: 0.75rem;
  line-height: 1.125rem;
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 0.6875rem 0.75rem 0.6875rem 3rem;
  border-bottom-color: transparent;
  transition: width 0.2s, padding 0.2s, border 0.2s;
}

.searchInput:focus {
  outline: none;
}

.open .searchInput {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.96875rem 0.75rem 0.96875rem 3rem;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.shade.open .searchInput {
  border-bottom-color: var(--ambient-basePlus75);
  background-color: var(--color-white);
}

.shade.open.valid {
  color: var(--turquoise-base);
}

.shade.open.valid .searchInput {
  color: var(--turquoise-base);
  border-bottom-color: var(--turquoise-base);
}

.ambient.open .searchInput {
  border-bottom-color: var(--accent-base);
}

.accent.open .searchInput {
  border-bottom-color: var(--lemon-base);
}

.shade .searchInput {
  background-color: var(--base10);
}

.ambient .searchInput {
  background-color: var(--ambient-base);
}

.ambient .searchInput,
.ambient .iconWrapper {
  color: var(--accent-base);
}

.accent .searchInput {
  background-color: var(--accent-base);
}

.accent .searchInput,
.accent .iconWrapper {
  color: var(--lemon-base);
}

.searchInput::placeholder {
  font-style: italic;
  opacity: 1;
}

.shade .searchInput::placeholder {
  color: var(--ambient-base);
}

.shade .searchInput:-ms-input-placeholder {
  color: var(--ambient-base);
}

.shade .searchInput::-ms-input-placeholder {
  color: var(--ambient-base);
}

.ambient .searchInput::placeholder,
.accent .searchInput::placeholder {
  color: var(--color-white);
}

.ambient .searchInput:-ms-input-placeholder,
.accent .searchInput:-ms-input-placeholder {
  color: var(--color-white);
}

.ambient .searchInput::-ms-input-placeholder,
.accent .searchInput::-ms-input-placeholder {
  color: var(--color-white);
}

.error.open .searchInput {
  color: var(--pink-base);
  border-bottom-color: var(--pink-base);
}

.error.open {
  color: var(--pink-base);
}
