.wrapper {
  position: relative;
}

.checkbox {
  padding: 1.25rem;
}

.dropDownWrapper {
  box-shadow: var(--shadow05);
  list-style: none;
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 30.625rem;
  max-height: calc(100vh - 7.5rem);
  overflow-y: auto;
  background-color: white;
  z-index: 100;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.listWrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tagWrapper {
  margin: 0.625rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.link {
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  color: var(--accent-base);
  margin-top: 1.25rem;
  display: inline-block;
  text-decoration: none;
  user-select: none;
  padding-right: 0.25rem;
  border: 0;
  cursor: pointer;
}

.link:not(first-child) {
  padding-left: 0.25rem;
}

.showAllLink {
  align-self: flex-end;
}
