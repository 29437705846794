.sensorIconAndTextGrouping {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sensorTypeText {
  display: flex;
  flex-direction: column;
  max-width: 10rem;
  text-overflow: ellipsis;
  font-weight: 600;
}

.secondaryText {
  padding-top: 0.2rem;
  font-weight: 400;
}
