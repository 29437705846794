.root {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
}

.item {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.icon {
  display: inline-block;
  margin-right: 0.5rem;
  min-width: 0.625rem;
}
