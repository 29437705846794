@import '../../../../root.css';

.wrapper {
  width: 60%;
  margin: 0 auto;
}

.conditionLabel {
  font-size: 0.875em;
  line-height: 1.25em;
  color: var(--ambient-base);
}

.accessDoor {
  color: var(--accent-base);
}

.doorClosed {
  color: var(--ambient-base);
}

.accessDoor p {
  margin: 1rem 0 0 0;
}

.right p,
.accessDoor p {
  font-size: 0.875em;
  line-height: 1.125em;
  color: var(--ambient-base);
  margin-bottom: 0;
}

.accessDoor,
.priority,
.reactionTime {
  display: flex;
  justify-content: space-between;
}

.accessDoor,
.priority {
  margin-bottom: 1.5rem;
}

.switchWrapper {
  display: flex;
  align-items: center;
}

.switchLabel {
  font-size: 1.125em;
  line-height: 1.5em;
  font-weight: 600;
  color: var(--ambient-base);
  padding-left: 0.5rem;
}

.right {
  width: 70%;
}

.helpText {
  display: flex;
}

.helpText p {
  margin: 0.2rem 0 0 0.2rem;
}

.infoIcon {
  color: var(--ambient-base);
}
