.root {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--ambient-base);
  padding: 0.375rem 0.625rem;
  border: none;
  background: none;
  cursor: pointer;
  margin-bottom: -0.3125rem;
  margin-right: 1.375rem;
}

.root:hover {
  color: var(--accent-base);
}

.root:last-child {
  margin-right: 0;
}

.isActive {
  border-bottom: 0.3125rem solid var(--accent-base);
}

.disabled {
  color: var(--base10);
  cursor: not-allowed;
}

.disabled:hover {
  color: var(--base10);
}
