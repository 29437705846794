.iconAndTextGrouping {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.textGrouping {
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  min-width: 0;
}

.titleText {
  font-size: 1em;
}

.pathText {
  font-size: 0.75em;
}
