.wrapper {
  color: var(--accent-base);
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding: 11px 10px;
  width: 100%;
  border-bottom: 1px solid #ebeced;
  cursor: pointer;
}

.wrapper:last-child {
  border: none;
}
