.root {
  appearance: none;
  background: transparent;
  border: none;
  top: 0;
  right: 0;
  outline: none;
  z-index: 999999;
  margin: 0;
  padding: 0;
  color: var(--accent-base);
}

.root:hover {
  opacity: 0.5;
  cursor: pointer;
}
