.modalContainer {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 30rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
}

.content {
  padding: 1.5625rem;
  width: 100%;
}

.close {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
}

.title {
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
  padding: 1.5625rem;
}
