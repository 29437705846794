.row {
  display: grid;
  font-size: 0.875rem;
  align-items: center;
  position: relative;
  padding: 0.625rem;
  grid-gap: 1rem;
  color: var(--ambient-base);
}

.row:not(:first-child) {
  align-items: start;
}

.disabled {
  background-color: var(--base5);
  color: var(--medium-base);
}

.selectable {
  cursor: pointer;
}
