.root {
  height: 3.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  color: var(--ambient-base);
  border-bottom: 5px solid transparent;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1rem;
}

.root:hover {
  color: var(--accent-base);
}

.active {
  background-color: var(--accent-base);
  border-bottom: 5px solid var(--accent-baseMinus25);
  color: var(--color-white);
}

.active:hover {
  color: var(--color-white);
}

.disabled {
  color: var(--ambient-basePlus75);
}

.disabled:hover {
  color: var(--ambient-basePlus75);
  cursor: auto;
}

.root-secondary {
  height: 3.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  margin-right: 1.375rem;
  color: #ffffff;
  border-bottom: 5px solid transparent;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.75rem;
}

.root-secondary:hover {
  color: var(--accent-base);
}

.active-secondary {
  border-bottom: 5px solid var(--accent-base);
  color: var(--accent-base);
}

.active-secondary:hover {
}
