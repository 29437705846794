.siteListItem {
  display: grid;
  grid-template-columns: 2fr 4fr 4fr;
  grid-gap: 1rem;
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.siteListItemCollapsed {
  height: 2.125rem;
  overflow: hidden;
}

.siteListItemNotCollapsed {
  padding-bottom: 10px;
}

.siteTitle {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 16px;
  color: var(--accent-base);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.siteTitleIcon {
  padding: 0.2rem;
  margin-right: 0.8rem;
}

.siteAddress {
  font-size: 0.75rem;
  font-style: italic;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  text-align: left;
  color: var(--ambient-base);
}

.table td,
.table th {
  padding: 0.625rem;
  font-size: 0.75rem;
  vertical-align: top;
}

.table th:last-child {
  text-align: center;
}

.table th,
.table td {
  padding-left: 0;
}

.action {
  text-align: center;
}

.actionHeader {
  word-spacing: 0.2rem;
}

.table thead th {
  font-weight: normal;
  vertical-align: bottom;
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.filterTotal {
  padding-left: 0.2rem;
}

.filterSelected {
  color: var(--accent-base);
  padding-right: 0.25rem;
}

.filterRow {
  cursor: pointer;
}

.icon {
  float: left;
  position: relative;
  top: -4px;
  margin-right: 0.5rem;
  color: var(--accent-base);
}

/* .flexRow{
  display:flex;
  flex-direction: column;
} */

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
}

.removeSiteIconColor {
  color: var(--pink-base);
  cursor: pointer;
}

.removeSiteIconColor:hover {
  color: var(--pink-baseMinus25);
}

.removeSite {
  padding-top: 0.8125rem;
}

.collapsedInfo {
  margin-left: 2.05rem;
}
