.root {
  position: relative;
  padding: 1.25rem;
  box-shadow: 0 0 1.25rem 0 #d8d8d8;
  display: grid;

  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 0.3125rem;
  border-bottom: 5px solid transparent;
  color: var(--accent-base);

  grid-template-rows: repeat(9, 1.25rem);
  grid-template-areas:
    'icon'
    'icon'
    'icon'
    'icon'
    'icon'
    'title'
    'location'
    'blank'
    '.';
}

.areaIcon {
  grid-area: icon;
}

.areaLabel {
  grid-area: label;
  align-items: center;
  display: flex;
}

.areaTitle {
  grid-area: title;
}

.areaLocation {
  grid-area: location;
}

.tooltipTitle {
  color: #ffffff;
  padding-bottom: 0.875rem;
  font-style: normal;
}

.pointer {
  cursor: pointer;
}

.rootStatusOn:hover {
  background-color: var(--turquoise-basePlus90);
}

.rootStatusOff {
  color: var(--ambient-basePlus50);
  background-color: var(--ambient-basePlus90);
  box-shadow: none;
}

.rootStatusStandby {
  color: var(--ambient-base);
}
.rootStatusOnPinned {
  color: var(--blue-base);
  border-bottom: 5px solid var(--blue-base);
}
.rootStatusOffPinned {
  border-bottom: 5px solid var(--ambient-basePlus50);
}
.rootStatusStandbyPinned {
  border-bottom: 5px solid var(--ambient-base);
}
.starButton {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  user-select: none;
  background: transparent;
  border: none;
  cursor: pointer;
}
.starIcon {
  stroke: var(--ambient-basePlus75);
  color: transparent;
}
.starIconPinned {
  stroke: transparent;
  color: var(--blue-base);
}
.starIconOffPinned {
  color: var(--ambient-basePlus50);
}

.starIconStandbyPinned {
  color: var(--ambient-base);
}

.title {
  line-height: 0.875rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  text-align: center;
  color: var(--ambient-base);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 1.25rem;
  max-width: 9rem;
}

.deviceState {
  display: flex;
  align-items: center;
}
.deviceStateOn {
  background-color: var(--blue-base);
}
.deviceStateStandby {
  background-color: var(--ambient-base);
}
.deviceStateOff {
  color: var(--ambient-base);
  background-color: var(--ambient-basePlus75);
}
.location {
  margin: 0;
  font-size: 0.75rem;
  color: var(--ambient-base);
  text-align: center;
}

.notification {
  padding-top: 10px;
  font-size: 0.75rem;
  color: var(--ambient-base);
}
