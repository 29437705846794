.input {
  width: 100%;
  padding: 5px 0;
  background: none;
  border: none;
  border-bottom: 1px solid var(--base25);
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--ambient-base);
  transition: border 0.5s;
}

.input:focus {
  border-bottom: 1px solid var(--base75);
  outline: none;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.errorInput {
  border-color: var(--pink-base);
}

.input::-webkit-input-placeholder {
  color: var(--ambient-basePlus50);
  font-size: 0.875rem;
  line-height: 1.125;
  font-weight: 400;
}

.isTitle {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.isMainTitle,
.isMainTitle::-webkit-input-placeholder {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
}
