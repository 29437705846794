.wrapper {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.wrapper span {
  display: flex;
  align-items: center;
}

.condensedText {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--accent-base);
  padding: 0 0.5rem;
  height: 0.75rem;
}

.dots {
  padding: 0 0.4rem 0 0.3rem;
}
