.root {
  display: grid;
  grid-template-areas: 'nav header' 'nav content' 'footer footer';
  grid-template-columns: 4.6875rem 1fr;
  grid-template-rows: min-content 1fr;
  height: 100vh;
}

.header {
  grid-area: header;
}

.nav {
  grid-area: nav;
  background-color: var(--ambient-base);
}

.content {
  grid-area: content;
  overflow-y: scroll;
}
