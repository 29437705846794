.root {
  margin-bottom: 1.25rem;
}

.title {
  font-size: 0.65rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-weight: normal;
  color: var(--ambient-basePlus50);
}

.description {
  margin: 0;
}
