.axis .tick text {
  font-family: 'Open Sans', sans-serif;
  font-size: 8px;
  font-weight: 600;
  fill: #989fa4;
  transform: translateY(10px);
}

.axis .domain,
.axis .tick line {
  stroke: #989fa4;
}

.axis-Left.axis .tick text {
  text-anchor: start;
  transform: translate(8px, -8px);
}

.axis-Left.axis .tick line {
  stroke: #e1e2e4;
}

.axis-Left.axis .tick:first-child line {
  stroke: #cbced1;
}

.barDiagram {
  width: 100%;
}

.barDiagram:hover .chartData {
  opacity: 0.5;
}

.barDiagram .chartData:hover {
  opacity: 1;
}

.toolTipWrapper {
  line-height: 1;
  font-weight: bold;
  padding: 6px 12px;
  background-color: #333e48;
  color: #fff;
  pointer-events: none;
  transform: translateY(-10px);
  text-align: center;
}

.toolTipWrapper:after {
  content: '';
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  pointer-events: none;
}

.n > .toolTipWrapper:after {
  content: '';
  margin: -1px 0 0 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333e48;
}

.toolTipWrapper strong {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  padding-bottom: 8px;
  display: block;
  font-style: normal;
}

.toolTipWrapper {
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.19);
}

.toolTipWrapper .firstYear {
  color: #3fb4f4;
}

.toolTipWrapper .secondYear {
  color: #72f482;
}

.legendText {
  font-size: 12px;
  line-height: 16px;
}
