:root {
  --accent-basePlus90: #e5f5f7;
  --accent-basePlus75: #bfe7eb;
  --accent-basePlus50: #7fd0d7;
  --accent-basePlus25: #3fb8c3;
  --accent-base: #00a1af;
  --accent-baseMinus10: #0597a4;
  --accent-baseMinus25: #0c8895;
  --accent-baseMinus50: #196f7b;
  --accent-baseMinus75: #265661;

  --ambient-basePlus90: #eaebec;
  --ambient-basePlus75: #ccced1;
  --ambient-basePlus50: #999ea3;
  --ambient-basePlus25: #666e75;
  --ambient-base: #333e48;
  --ambient-baseMinus10: #2d3740;
  --ambient-baseMinus25: #262e36;
  --ambient-baseMinus50: #191f24;
  --ambient-baseMinus75: #0c0f12;

  --blue-basePlus90: #ebf8fd;
  --blue-basePlus75: #cfeefc;
  --blue-basePlus50: #9fddf9;
  --blue-basePlus25: #6fccf6;
  --blue-base: #3fbcf4;
  --blue-baseMinus10: #3dafe2;
  --blue-baseMinus25: #3c9cc9;
  --blue-baseMinus50: #397d9e;
  --blue-baseMinus75: #365d73;

  --turquoise-basePlus90: #e9fbfa;
  --turquoise-basePlus75: #caf6f3;
  --turquoise-basePlus50: #95eee7;
  --turquoise-basePlus25: #60e5db;
  --turquoise-base: #2bddcf;
  --turquoise-baseMinus10: #2bcdc1;
  --turquoise-baseMinus25: #2db5ad;
  --turquoise-baseMinus50: #2f8d8b;
  --turquoise-baseMinus75: #316569;

  --green-basePlus90: #f1fdf2;
  --green-basePlus75: #ddfae0;
  --green-basePlus50: #bbf5c2;
  --green-basePlus25: #99f0a3;
  --green-base: #77eb85;
  --green-baseMinus10: #70d97e;
  --green-baseMinus25: #66bf75;
  --green-baseMinus50: #559466;
  --green-baseMinus75: #446957;

  --lemon-basePlus90: #fdfeeb;
  --lemon-basePlus75: #fcfdcf;
  --lemon-basePlus50: #f9fb9f;
  --lemon-basePlus25: #f6f96f;
  --lemon-base: #f3f73f;
  --lemon-baseMinus10: #dfe43f;
  --lemon-baseMinus25: #c3c841;
  --lemon-baseMinus50: #939a43;
  --lemon-baseMinus75: #636c45;

  --sun-basePlus90: #fffaea;
  --sun-basePlus75: #fff2cc;
  --sun-basePlus50: #ffe699;
  --sun-basePlus25: #ffda66;
  --sun-base: #ffce34;
  --sun-baseMinus10: #eabf36;
  --sun-baseMinus25: #ccaa39;
  --sun-baseMinus50: #99863e;
  --sun-baseMinus75: #666243;

  --orange-basePlus90: #fff4ec;
  --orange-basePlus75: #ffe3d1;
  --orange-basePlus50: #ffc8a3;
  --orange-basePlus25: #ffad75;
  --orange-base: #ff9248;
  --orange-baseMinus10: #ea8948;
  --orange-baseMinus25: #cc7d48;
  --orange-baseMinus50: #996848;
  --orange-baseMinus75: #665348;

  --pink-basePlus90: #ffebf1;
  --pink-basePlus75: #ffcfde;
  --pink-basePlus50: #ff9fbd;
  --pink-basePlus25: #ff6f9c;
  --pink-base: #ff407b;
  --pink-baseMinus10: #ea3f75;
  --pink-baseMinus25: #cc3f6e;
  --pink-baseMinus50: #993f61;
  --pink-baseMinus75: #663e54;

  --purple-basePlus90: #f7f1fc;
  --purple-basePlus75: #eddcf8;
  --purple-basePlus50: #dbb9f1;
  --purple-basePlus25: #c996ea;
  --purple-base: #b873e4;
  --purple-baseMinus10: #aa6dd4;
  --purple-baseMinus25: #9665bd;
  --purple-baseMinus50: #755896;
  --purple-baseMinus75: #544b6f;

  --indigo-basePlus90: #eff0fe;
  --indigo-basePlus75: #d7dafd;
  --indigo-basePlus50: #b0b6fc;
  --indigo-basePlus25: #8991fb;
  --indigo-base: #626dfa;
  --indigo-baseMinus10: #5d68e8;
  --indigo-baseMinus25: #5661cd;
  --indigo-baseMinus50: #4a55a1;
  --indigo-baseMinus75: #3e4974;

  --color-text: var(--ambient-base);
  --color-white: #fff;
  --base5: rgba(51, 62, 72, 0.05);
  --base10: rgba(51, 62, 72, 0.1);
  --base25: rgba(51, 62, 72, 0.25);
  --base50: rgba(51, 62, 72, 0.5);
  --base75: rgba(51, 62, 72, 0.75);

  --medium-base: #989fa4;

  --accent-transparent: rgba(0, 160, 175, 0.05);

  --shadow05: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.1);
  --shadow10: 0 0 0.625rem 0 rgba(0, 0, 0, 0.15);
  --shadow30: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  --shadow20: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
}
