.unselectAll {
  color: var(--accent-base);
  font-size: 0.75em;
  user-select: none;
  padding-bottom: 1.25rem;
}

.openContainer {
  padding: 1.25rem;
  padding-bottom: 1.875rem;
  background-color: #ffffff;
}

.actionsFooter {
  margin: 2rem 0 0 2rem;
}
