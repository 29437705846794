.root {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}

.controls {
  display: flex;
  justify-content: center;
}

.button {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.button:first-child {
  margin-right: 1.625rem;
}

.tip {
  display: flex;
}

.finishLaterButton {
  color: var(--accent-base);
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
