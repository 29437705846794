.root {
}

.legend {
  float: right;
}

.legend > div {
  display: inline-block;
  margin: 0 10px;
  font-size: 14px;
}

.legend > div > span {
  margin-left: 10px;
}

.legend .turquoise {
  color: var(--turquoise-base);
}

.legend .blue {
  color: var(--blue-base);
}

.legend .lemon {
  color: var(--lemon-baseMinus25);
}

.scheduleContainer {
  position: relative;
  margin-top: 40px;
}

.scheduleContainerColumns {
  display: grid;
  grid-template-columns: 300px repeat(24, auto) 100px;
}

.scheduleContainerColumns > div {
  min-height: 50px;
  height: 100%;
  width: 100%;
}

.scheduleContainerColumns > div:not(:first-child):nth-child(even) {
  border-left: dashed #b6b6b6 1px;
}

.scheduleContainerColumns > div:not(:first-child):nth-child(odd) {
  border-left: dotted #e6e6e6 1px;
}

.scheduleContainerColumns > div > span {
  margin-top: -15px;
  display: inline-block;
  position: absolute;
  font-size: 10px;
  color: #959595;
}

.scheduleContainerColumns > div > .status {
  font-size: 12px;
  color: #000;
  text-align: center;
}

.projectionsWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  border: solid black 1px;
}

.projectionsWrapper .daylight {
  position: absolute;
  background-color: rgba(195, 200, 64, 0.4);
  margin-top: 20px;
  margin-left: 37%;
  height: 30px;
  width: 50%;
}

.projectionsWrapper .localRegulations {
  position: absolute;
  background-color: rgba(63, 188, 244, 0.4);
  margin-top: 35px;
  margin-left: 30%;
  height: 14px;
  width: 60%;
}

.projectionsWrapper .openningHours {
  position: absolute;
  background-color: rgba(44, 221, 206, 0.4);
  margin-top: 50px;
  margin-left: 32%;
  height: 14px;
  width: 52%;
}

.scheduleItem {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  border-top: solid #46b6c1 3px;
}

.scheduleItem .title {
  padding: 10px 0;
  width: 300px;
  margin-bottom: 40px;
}

.scheduleItem .title > div:first-child {
  color: #46b6c1;
  font-weight: bold;
}

.scheduleItem .equipmentRow {
  position: relative;
  margin-top: 5px;
  background-color: rgba(204, 206, 209, 0.3);
}

.scheduleItem .equipmentRow:first {
  margin-top: 40px;
}

.scheduleItem .equipmentRow.enabled.color1 {
  background-color: rgba(255, 206, 52, 0.3);
}

.scheduleItem .equipmentRow.enabled.color2 {
  background-color: rgba(60, 180, 243, 0.3);
}

.scheduleItem .equipmentRow .equipmentName {
  display: flex;
  align-items: center;
}

.scheduleItem .equipmentRow .timeRange {
  position: absolute;
  color: #fff;
  padding: 10px;
  top: 0;
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--ambient-basePlus75);
}

.scheduleItem .equipmentRow.enabled.color1 .timeRange {
  background-color: rgb(255, 206, 52);
}

.scheduleItem .equipmentRow.enabled.color2 .timeRange {
  background-color: #3fb4f3;
}

.scheduleItem .equipmentRow .timeRange .statusLabel {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px;
  border-radius: 10px;
  font-size: 10px;
  width: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.scheduleItem .equipmentRow .switchButton {
  position: absolute;
  top: 30px;
  right: 15px;
  width: 50px;
  border-radius: 20px;
  color: #fff;
  background-color: var(--ambient-basePlus75);
  padding: 1px;
  cursor: pointer;
}

.scheduleItem .equipmentRow .switchButton > span:first-child {
  font-size: 12px;
}

.scheduleItem .equipmentRow.enabled.color1 .switchButton {
  background-color: #ffce34;
}

.scheduleItem .equipmentRow.enabled.color2 .switchButton {
  background-color: #3fb4f3;
}

.scheduleItem .equipmentRow .switchButton .toggle {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}

.scheduleItem .equipmentRow.enabled .switchButton .toggle {
  float: right;
}

.scheduleItem .equipmentRow .switchButton > span {
  display: inline-block;
  margin-top: 2px;
  margin-left: 3px;
  font-size: 12px;
}

.addControlButton {
  margin: 20px;
}
