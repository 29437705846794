@import '../../../root.css';

.stepWrapper {
  font-size: 0.75rem;
  line-height: 17px;
  color: var(--ambient-base);
  width: 100%;
}

.stepWrapperTitle {
  width: 50%;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.stepWrapper form p {
  margin: 2rem 0;
}

.formHeading {
  border-bottom: 1px solid var(--base25);
  padding-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 3fr;
  grid-column-gap: 2rem;
}

.formContent {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 3fr;
  grid-column-gap: 2rem;
}

.paddingTop {
  padding-top: 2rem;
}
