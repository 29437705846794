.root {
  background-color: var(--base5);
  padding: 2.8125rem;
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metaContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -14px;
}

.sentinelTypeIconContainer {
  position: absolute;
  width: 60px;
  height: 60px;
  left: -30px;
  top: 45px;
  background: linear-gradient(
    to right bottom,
    var(--turquoise-basePlus25),
    var(--turquoise-baseMinus50)
  );
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rolloutContainer {
  overflow-y: scroll;
  height: 100%;
  max-height: 16rem;
  padding-top: 0.375rem;
}

.tabsContainer {
  padding-top: 1.5rem;
  margin-bottom: 2rem;
}

.bottomGroup {
  display: grid;
  grid-template-columns: 2fr 4fr 4fr;
  grid-gap: 1rem;
}

.bottomGroupButtons button:first-child {
  margin-right: 1rem;
}

.bottomGroupButtons {
  display: flex;
  justify-content: flex-end;
}

.greenText {
  color: var(--accent-base);
}

.forceRedAndNoSelect {
  color: var(--pink-base);
  user-select: none;
  cursor: pointer;
}

.forceRedAndNoSelect:hover {
  color: var(--pink-baseMinus25);
}

.forceBlack {
  color: var(--ambient-base);
  align-items: center;
  justify-content: left;
}

.steps {
  flex-grow: 1;
}

.formWrap {
  display: flex;
  flex-direction: column;
}

.error {
  margin: 0;
  padding-top: 2rem;
}

.bottomLine {
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.deleteChip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
