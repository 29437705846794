.iconAndTextGrouping {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconAndTextGrouping span {
  padding: 0.8125rem 0 0.8125rem 0;
}

.iconAndTextGrouping .iconStyle {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--accent-base);
}

.numberCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.375rem;
  height: 0.75rem;
  line-height: 0.625rem;
  border-radius: 1rem;
  font-size: 0.625rem;
  font-weight: 600;
  color: black;
  text-align: center;
  background: var(--lemon-base);
  position: absolute;
  top: 0;
  right: -0.8rem;
}
