.root {
  position: relative;
  padding: 1.25rem 1.75rem;
  box-shadow: var(--shadow10);

  cursor: pointer;

  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 0.3125rem;
  border-bottom: 5px solid transparent;
  color: var(--ambient-base);

  grid-template-rows: repeat(14, 1.25rem);
  grid-template-areas:
    'icon'
    'icon'
    'icon'
    'icon'
    'icon'
    'icon'
    'status'
    'status'
    'title'
    'title'
    'location'
    '.'
    '.'
    'warning';
}

.root:hover {
  background-color: var(--turquoise-basePlus90);
}

.rootStatusOffline {
  background-color: var(--ambient-basePlus90);
  border-bottom: 5px solid var(--pink-base);
  box-shadow: none;
}

.rootStatusNotInstalled {
  background-color: var(--ambient-basePlus90);
  box-shadow: none;
}

.icon {
  color: var(--accent-base);
  grid-area: icon;
  align-self: end;
}

.title {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  color: var(--ambient-base);
  grid-area: title;
}

.status {
  grid-area: status;
}

.location {
  margin: 0;
  font-size: 0.75rem;
  color: var(--ambient-base);
  grid-area: location;
}

.siteStateOff {
  box-shadow: none;
  color: var(--ambient-base);
  background-color: var(--ambient-basePlus75);
}

.offlineIcon {
  color: var(--ambient-basePlus50);
}

.warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-area: warning;
  font-style: italic;
  color: var(--pink-base);
  font-size: 0.75rem;
}

.paddingRight {
  padding-right: 0.375rem;
}

.highlightedText {
  color: var(--turquoise-base);
}
