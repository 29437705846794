.iconAndTextGrouping {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textGrouping {
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  min-width: 0;
}

.titleText {
  font-weight: bold;
  padding-bottom: 0.3rem;
}
