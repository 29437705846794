.background {
  background-color: white;
}

.rowExpanded {
  display: inherit;
  padding: 0;
}

.contentInner {
  padding-left: 1rem;
}

.col {
  flex: 1;
}

.col2 {
  flex: 2;
}

.col4 {
  flex: 4 10rem;
}
