@import '../root.css';

.wrapper {
  position: relative;
}

.textWrapper {
  color: var(--accent-base);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.textWrapper:focus {
  outline: none;
}

.sortText {
  color: var(--ambient-base);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin: 0 0 0 0.3125rem;
  padding: 0;
}

.selected {
  color: var(--accent-base);
}

.itemsWrapper {
  min-width: 12.5rem;
  max-height: 25vh;
  overflow-y: scroll;
  position: absolute;
  z-index: 999;
  align-self: end;
  list-style: none;
  top: 1.5rem;
  margin: 0;
  padding: 0;
  background-color: var(--color-white);
  box-shadow: var(--shadow05);
}

.flexWrapper {
  display: flex;
}

.right {
  left: 0;
}

.left {
  right: 0;
}
