.slice {
  fill: currentColor;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sliceContainer:hover .slice {
  stroke-width: 3px;
  stroke: currentColor;
}

.sliceText {
  font-size: 12px;
  line-height: 12px;
  fill: white;
  user-select: none;
}
