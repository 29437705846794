.tooltip {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.tooltipComment {
  border-bottom: 1px dotted var(--base25);
}

.tooltipText {
  visibility: hidden;
  background-color: #333e48;
  color: #ffffff;
  text-align: center;
  padding: 0.875rem;
  position: absolute;
  z-index: 1;
}

.tooltip .tooltipText.bottom {
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltipText.top {
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltipText.right {
  left: 110%;
  transform: translateY(-45%);
}

.tooltip .tooltipText.left {
  right: 110%;
  transform: translateY(-45%);
}

.tooltip .tooltipText.bottom::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333e48 transparent;
}

.tooltip .tooltipText.right::after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333e48 transparent transparent;
}

.tooltip .tooltipText.left::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #333e48;
}

.tooltip .tooltipText.top::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333e48 transparent transparent transparent;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  z-index: 9999;
}

.hoverOverride .tooltipText {
  visibility: visible;
}
