.navItem {
  width: 75px;
  list-style: none;
}

.navItemLink {
  color: var(--color-white);
  background-color: var(--ambient-base);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  border-left: 5px solid transparent;
  transition: all 0.5s;
  cursor: pointer;
}

.navItemLink:hover {
  background-color: var(--accent-baseMinus50);
}

.active {
  color: var(--accent-basePlus25);
  background-color: var(--accent-baseMinus50);
  border-left: 5px solid var(--accent-basePlus25);
  transition: all 0.5s;
}
