@import '../../root.css';

.container {
  text-align: center;
}

.circleText {
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text);
  alignment-baseline: middle;
  text-anchor: middle;
}

.circleTextTop {
  transform: translate(0, -30px);
}

.circleTextBottom {
  transform: translate(0, 30px);
}

.totalSumText {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  color: var(--color-text);
  text-anchor: middle;
  alignment-baseline: middle;
}

.slice {
  stroke: white;
  transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
