.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5625rem;
}

.buttonGroup > :first-child {
  margin-bottom: 0.625rem;
}

.infoText {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  padding: 1.5625rem;
}

.configName {
  font-weight: 700;
}
