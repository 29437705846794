.backButton {
  color: var(--accent-base);
  background-color: var(--ambient-baseMinus25);
  width: 3.6875rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.75rem;
}

.titleWrapper {
  padding-left: 3.5rem;
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  font-size: 0.875em;
  line-height: 1.188em;
  font-weight: 700;
  color: #ffffff;
  margin-right: 1.375rem;
}
.horizontalFlexWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtitle {
  font-size: 0.75em;
  padding-top: 0.4em;
  color: var(--ambient-basePlus50);
}
