.nav {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: inline-block;
}

.navFixed {
  position: fixed;
  top: 0;
  left: 0;
}

.navItem {
  width: 4.6875rem;
  list-style: none;
}

.logoLink {
  color: var(--accent-base);
  background-color: var(--ambient-baseMinus25);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
}

.topPadding {
  padding-top: 5rem;
}
