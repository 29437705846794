.flex {
  display: flex;
}

.timeText {
  color: var(--accent-base);
}

.tooltipTimeText {
  color: var(--ambient-basePlus50);
}

.blackIcon {
  color: var(--ambient-base);
}

.icon-online {
  color: var(--accent-base);
}

.icon-offline {
  color: var(--pink-base);
}

.icon-unpaired {
  color: var(--orange-base);
}

.row-online {
  border-left-width: 0.1875rem;
  border-left-style: solid;
  border-left-color: transparent;
}

.row-offline {
  border-left-width: 0.1875rem;
  border-left-style: solid;
  border-left-color: var(--pink-base);
}

.row-unpaired {
  border-left-width: 0.1875rem;
  border-left-style: solid;
  border-left-color: var(--orange-base);
}

.iconPaddingRight {
  padding-right: 1rem;
}

.equipmentText {
  text-overflow: ellipsis;
  overflow: hidden;
}

.iconFlexCenter {
  display: flex;
  justify-content: center;
}

.description {
  white-space: normal;
}

.iconPaddingMimic {
  padding-left: 1.9375rem;
}

.showOverflow {
  overflow: visible;
}

.borderLeft {
  border-left: 1px solid var(--base10);
}
