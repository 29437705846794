@import '../../../root.css';

.root {
}

.legendGroup {
  color: var(--ambient-basePlus75);
}

.legendTimeScale {
  fill: currentColor;
}

.legendTimeScaleText {
  font-size: 0.51rem;
  fill: currentColor;
}

.legendOfficeHours {
  fill: var(--lemon-base);
}

.bar {
  fill: var(--turquoise-base);
  opacity: 0.5;
}

.radial {
  fill: currentColor;
}

.middleCircle {
  fill: #ffffff;
}

.circleText {
  fill: currentColor;
  text-anchor: middle;
  font-size: 0.51rem;
  line-height: 10px;
}

.legendWrapper {
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
}

.legendText {
  font-size: 0.75rem;
  line-height: 1.3;
  margin-top: 0.625rem;
  margin-left: 1rem;
}

.legendText:nth-child(1):before {
  background-color: var(--turquoise-base);
}

.legendText:nth-child(2):before {
  background-color: var(--lemon-base);
}

.legendCircleWrapper {
  display: flex;
}

.legendCircle {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-left: 0.2rem;
  margin-top: 0.625rem;
}

.legendTypeWrapper .legendText {
  display: flex;
  align-items: center;
}

.legendTypeWrapper .legendText:before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 0.2rem;
  margin-right: 0.5rem;
}
