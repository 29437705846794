.iconAndTextGrouping {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconAndTextGrouping span {
  margin: 0.625rem 0;
  padding: 0.625rem 1.25rem;
  border-left: 1px solid var(--base10);
}

.iconAndTextGrouping .iconStyle {
  color: var(--accent-base);
}
