.label {
  display: block;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--ambient-basePlus50);
}

.fullWidth {
  display: block;
  width: 100%;
}
