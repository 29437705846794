.root {
  height: 3.75rem;
  background-color: var(--ambient-basePlus90);
  padding: 0 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.root > *:not(:last-child) {
  margin-right: 1rem;
}
