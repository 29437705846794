.header {
  display: flex;
  align-items: center;
  height: 3.75rem;
  background-color: var(--ambient-base);
  padding: 0 1.25rem;
}

.header h1 {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 3.75rem);
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.form {
  display: flex;
  justify-content: center;
  width: 25rem;
  padding: 5.625rem 1.875rem;
  background: var(--color-white);
}

.form h2,
.form p {
  margin: 0;
  padding: 0;
}

.form h2 {
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-weight: 700;
}

.form p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1.25rem 0 2.5rem 0;
}

.formInnerWrapper {
  width: 12.5rem;
  text-align: center;
}

.formField {
  margin-top: 1.5rem;
}

.loginButtonWrapper {
  margin-top: 3.75rem;
}
