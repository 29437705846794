.root {
  height: 3.375rem;
  position: relative;
  transition: width 0.2s;
}

.wrapper {
  width: 100%;
  position: absolute;
  z-index: 999;
  display: inline-block;
}

.wrapperOpen {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
