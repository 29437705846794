.card {
  box-shadow: var(--shadow05);
  padding: 1.25rem;

  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;

  grid-template-rows: repeat(9, 1.25rem);
  grid-template-areas:
    'icon'
    'icon'
    'icon'
    'icon'
    'title'
    'title'
    'role'
    'placeholder'
    'extra';
}

.icon {
  grid-area: icon;
}

.title {
  grid-area: title;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--ambient-base);
}

.role {
  grid-area: role;
  font-size: 0.75rem;
  color: var(--accent-base);
}

.address {
  grid-area: address;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--ambient-base);
}

.contact {
  grid-area: contact;
  font-size: 0.75rem;
}

.extra {
  grid-area: extra;
  font-size: 0.75rem;
  font-style: italic;
}
