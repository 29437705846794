.root {
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background-color: transparent;
  cursor: pointer;
  height: 1.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid var(--ambient-basePlus75);
  text-transform: capitalize;
}

.root:hover {
  border-color: var(--ambient-basePlus50);
}

.open {
  color: white;
  background-color: var(--accent-base);
  border-color: transparent;
}

.open:hover {
  color: white;
  background-color: var(--accent-baseMinus25);
  border-color: transparent;
}

.active {
  color: var(--accent-base);
  border-color: var(--accent-base);
}

.active:hover {
  color: var(--accent-base);
  border-color: var(--accent-baseMinus25);
}
